import React from 'react';
import {Helmet} from "react-helmet";
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Curtis Andrews | simplycurt.is</title>
        <link href="https://fonts.googleapis.com/css?family=Raleway:700,300,100" rel="stylesheet" type="text/css"></link>
      </Helmet>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Curtis Andrews</h1>
        <h2>User Interface Engineer</h2>
        <ol>
          <li>Arity</li>
          <li>c|change</li>
          <li>The Nerdery</li>
          <li>Sherwood Group</li>
        </ol>
      </header>
    </div>
  );
}

export default App;
